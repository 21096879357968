import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { applyTimeZone } from '../../interface/assistencia/LlistaAssistencies';

// Register Chart.js and the annotation plugin
Chart.register(...registerables, annotationPlugin);

const AuthorHistogram = ({ data, nHours, castellersInfo, events }) => {

    const decodeBase64 = (encodedString) => {
        const uint8Array = Uint8Array.from(atob(encodedString), c => c.charCodeAt(0));
        const decodedString = new TextDecoder().decode(uint8Array);
        return decodedString;
    }

    const chartData = React.useMemo(() => {
        const now = Date.now();
        const nHoursInMs = nHours * 60 * 60 * 1000;
        const nHoursAgo = new Date(now - nHoursInMs);
        
        // Filter data within the specified time range
        const filteredData = data.filter(entry => {
            const timestamp = new Date(entry.timestamp).getTime();
            return timestamp >= nHoursAgo.getTime() && timestamp <= now;
        });

        // Generate time slots and create a map for quick index lookup
        const timeSlots = [];
        const timeSlotMap = new Map();
        for (let i = 0; i < nHours * 4; i++) {
            const time = new Date(nHoursAgo.getTime() + i * 15 * 60 * 1000);
            const day = time.getDate();
            const month = time.getMonth() + 1;
            const hour = time.getHours();
            const minute = Math.floor(time.getMinutes() / 15) * 15;
            const label = `${day}/${month} ${hour}:${minute.toString().padStart(2, '0')}`;
            timeSlots.push(label);
            timeSlotMap.set(label, i);
        }

        // Group data by author and time slot
        const groupedData = {};
        filteredData.forEach(entry => {
            const entryDate = new Date(entry.timestamp);
            const day = entryDate.getDate();
            const month = entryDate.getMonth() + 1;
            const hour = entryDate.getHours();
            const minute = Math.floor(entryDate.getMinutes() / 15) * 15;
            const timeSlot = `${day}/${month} ${hour}:${minute.toString().padStart(2, '0')}`;
            const author = entry.author;

            if (!groupedData[author]) {
                groupedData[author] = new Array(nHours * 4).fill(0);
            }

            const slotIndex = timeSlotMap.get(timeSlot);
            if (slotIndex !== undefined) {
                groupedData[author][slotIndex]++;
            }
        });

        // Assign consistent colors to authors based on their name
        const authorColors = {};
        const generateColorFromString = (str) => {
            // Use multiple hash functions for more randomness
            let hash1 = 0;
            let hash2 = 0;
            let hash3 = 0;
            
            for (let i = 0; i < str.length; i++) {
                // Different hash functions for each component
                hash1 = str.charCodeAt(i) + ((hash1 << 7) - hash1);
                hash2 = str.charCodeAt(i) + ((hash2 << 11) - hash2);
                hash3 = str.charCodeAt(i) + ((hash3 << 13) - hash3);
            }
            
            // Add more randomization by mixing the bits
            const r = Math.abs((hash1 ^ (hash2 >> 13) ^ (hash3 << 7)) % 256);
            const g = Math.abs((hash2 ^ (hash3 >> 11) ^ (hash1 << 5)) % 256);
            const b = Math.abs((hash3 ^ (hash1 >> 17) ^ (hash2 << 3)) % 256);
            
            return `rgba(${r}, ${g}, ${b}, 0.8)`;
        };
        Object.keys(groupedData).forEach(author => {
            if (!authorColors[author]) {
                authorColors[author] = generateColorFromString(author);
            }
        });

        // Convert grouped data to Chart.js datasets
        const datasets = Object.keys(groupedData).map(author => {
            const label = castellersInfo[author]?.mote 
                ? castellersInfo[author].mote 
                : author in castellersInfo 
                    ? `${castellersInfo[author].nom} ${castellersInfo[author].cognom}` 
                    : author;
            return {
                label,
                data: groupedData[author],
                backgroundColor: authorColors[author],
            };
        });

        return { labels: timeSlots, datasets };
    }, [data, nHours, castellersInfo]);

    // Process events to get the start times within the selected nHours
    const eventsWithinNHours = events.filter(event => {
        const eventDate = applyTimeZone(event['data-esperada-inici']).toDate();
        const nHoursAgo = new Date(Date.now() - nHours * 60 * 60 * 1000);
        return new Date() > eventDate && eventDate > nHoursAgo;
    })

    const eventStartTimes = eventsWithinNHours
        .map(event => {
            const eventDate = applyTimeZone(event['data-esperada-inici']).toDate();
            return {
                value: `${eventDate.getDate()}/${eventDate.getMonth() + 1} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`,
                time: eventDate.getTime(), // Store the time for sorting
                ...event
            };
        });


    // Sort events by time
    eventStartTimes.sort((a, b) => a.time - b.time);

    const eventsAnnotations = eventStartTimes.map(event => ({
        id: `event-${event.id}`,
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: event.value,
        borderColor: 'red',
        borderWidth: 2,
        label: {
            rotation: 'auto',
            content: decodeBase64(event.title),
            display: true,
            position: 'start', // This will put the label at the top of the line
            font: {
                size: 6
            },
        },
    }))

    // Chart options with annotations
    const chartOptions = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
        maintainAspectRatio: false,
        plugins: {
            annotation: {
                annotations: {
                    ...eventsAnnotations
                }
            }
        },
    };

    return (
        <>
            <div style={{ height: '400px', marginBottom: 75 }}>
                <h2>Activitat de cada membre durant les últimes {nHours} hores</h2>
                <Bar
                    data={chartData}
                    options={chartOptions}
                />
            </div>
        </>
    );
};

export default AuthorHistogram;
