import { useState, useEffect } from 'react';
import { fetchAPI, postAPI } from '../../utils/utils';
import UserInfo from '../login/UserInfo';
import { HeaderTabs } from '../interface/ProvesApp';
import Pressable from '../other/Pressable';

function EnllaçosApp({ userInfo, castellersInfo, ...props }) {
    const [links, setLinks] = useState([]);
    const [editingLink, setEditingLink] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [newUrl, setNewUrl] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [showForm, setShowForm] = useState(false);

    const styles = {
        container: {
            padding: '30px',
            maxWidth: '80%',
            margin: '0 auto',
        },
        header: {
            marginTop: '30px',
            color: '#333'
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            marginBottom: '30px',
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        },
        input: {
            padding: '12px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '16px'
        },
        linkUrl: {
            fontSize: '14px',
            color: '#888',
            marginBottom: '10px'
        },
        textarea: {
            padding: '12px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '16px',
            resize: 'vertical',
            minHeight: '100px'
        },
        buttonGroup: {
            display: 'flex',
            gap: '10px'
        },
        submitButton: {
            padding: '12px 20px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            flex: 1
        },
        cancelButton: {
            padding: '12px 20px',
            backgroundColor: '#6c757d',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            flex: 1
        },
        linkCard: {
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            marginBottom: '20px',
            transition: 'transform 0.2s'
        },
        linkTitle: {
            fontSize: '20px',
            color: '#007bff',
            textDecoration: 'none',
            marginBottom: '10px'
        },
        linkDescription: {
            fontSize: '16px',
            color: '#555',
            marginBottom: '15px'
        },
        actionButtons: {
            display: 'flex',
            gap: '10px'
        },
        editButton: {
            padding: '8px 16px',
            backgroundColor: '#ffc107',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px'
        },
        deleteButton: {
            padding: '8px 16px',
            backgroundColor: '#dc3545',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px'
        },
        moveButton: {
            padding: '6px 12px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            background: '#fff',
            cursor: 'pointer',
            fontSize: '14px',
            color: '#6c757d',
            minWidth: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    };

    useEffect(() => {
        fetchAPI('/links', setLinks);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const linkData = {
            title: newTitle.trim(),
            url: newUrl.trim(),
            description: newDescription.trim()
        };

        if (editingLink !== null) {
            // Edit existing link
            postAPI('/edit_link', { ...linkData, id: editingLink }, () => {
                setLinks(links.map(link => 
                    link.id === editingLink ? { ...linkData, id: editingLink } : link
                ));
                resetForm();
            });
        } else {
            // Create new link
            postAPI('/add_link', linkData, (response) => {
                setLinks([...links, { ...linkData, id: response.id }]);
                resetForm();
            });
        }
    };

    const handleDelete = (id) => {
        if (window.confirm('Estàs segur que vols eliminar aquest enllaç?')) {
            postAPI('/delete_link', { id }, () => {
                setLinks(links.filter(link => link.id !== id));
            });
        }
    };

    const handleEdit = (link) => {
        setShowForm(true);
        setEditingLink(link.id);
        setNewTitle(link.title);
        setNewUrl(link.url);
        setNewDescription(link.description);
    };

    const resetForm = () => {
        setEditingLink(null);
        setNewTitle('');
        setNewUrl('');
        setNewDescription('');
        setShowForm(false);
    };

    const renderForm = (inlineStyle = {}) => (
        <form onSubmit={handleSubmit} style={{...styles.form, ...inlineStyle}}>
            <h3 style={{
                fontSize: '20px',
                marginBottom: '15px',
                color: '#333'
            }}>
                {editingLink !== null ? 'Editar enllaç' : 'Afegir nou enllaç'}
            </h3>
            <input
                type="text"
                placeholder="Títol"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                style={styles.input}
                required
            />
            <input
                type="url"
                placeholder="URL"
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
                style={styles.input}
                required
            />
            <textarea
                placeholder="Descripció"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                style={styles.textarea}
            />
            <div style={styles.buttonGroup}>
                <button type="submit" style={styles.submitButton}>
                    {editingLink !== null ? 'Desar Canvis' : 'Afegir Enllaç'}
                </button>
                <button 
                    type="button" 
                    onClick={resetForm}
                    style={styles.cancelButton}
                >
                    Cancel·lar
                </button>
            </div>
        </form>
    );

    const handleMove = (id, direction) => {
        postAPI('/reorder_link', { id, direction }, (response) => {
            if (response.error) {
                console.error('Error reordering:', response.error);
                return;
            }
            // Refresh the links list
            fetchAPI('/links', (links) => {
                if (Array.isArray(links)) {
                    setLinks(links);
                }
            });
        });
    };

    return (
        <div style={styles.container}>
            <UserInfo userInfo={userInfo} castellersInfo={castellersInfo} {...props} />

            <HeaderTabs userInfo={userInfo} {...props} />

            <Pressable style={{ backgroundColor: '#eee' }} className="boto-back" href='/'>
                ← Tornar a la pàgina principal
            </Pressable>

            <h2 style={styles.header}>
                Enllaços importants
            </h2>

            {!editingLink && showForm && renderForm()}

            {!editingLink && !showForm && (
                <button 
                    onClick={() => setShowForm(true)} 
                    style={{
                        padding: '12px 24px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        margin: '20px 0',
                        transition: 'background-color 0.2s',
                        ':hover': {
                            backgroundColor: '#0056b3'
                        }
                    }}
                >
                    <span style={{fontSize: '20px'}}>+</span>
                    Afegir nou enllaç
                </button>
            )}

            <div>
                {links.map((link, index) => (
                    editingLink === link.id ? (
                        <div key={link.id} style={styles.linkCard}>
                            {renderForm({ marginBottom: '20px' })}
                        </div>
                    ) : (
                        <div key={link.id} style={{
                            ...styles.linkCard,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px'
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}>
                                <div style={{flex: 1}}>
                                    <a 
                                        href={link.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        style={{
                                            ...styles.linkTitle,
                                            display: 'block',
                                            fontWeight: 500,
                                            marginBottom: '4px'
                                        }}
                                    >
                                        {link.title}
                                    </a>
                                    <p style={{
                                        ...styles.linkUrl,
                                        margin: 0,
                                        fontSize: '13px',
                                        color: '#666',
                                        maxWidth: '300px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {link.url}
                                    </p>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    gap: '8px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '4px'
                                    }}>
                                        <button 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleMove(link.id, 'up');
                                            }}
                                            disabled={index === 0}
                                            style={{
                                                ...styles.moveButton,
                                                opacity: index === 0 ? 0.5 : 1
                                            }}
                                        >
                                            ↑
                                        </button>
                                        <button 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleMove(link.id, 'down');
                                            }}
                                            disabled={index === links.length - 1}
                                            style={{
                                                ...styles.moveButton,
                                                opacity: index === links.length - 1 ? 0.5 : 1
                                            }}
                                        >
                                            ↓
                                        </button>
                                    </div>
                                    <button 
                                        onClick={() => handleEdit(link)}
                                        style={{
                                            padding: '6px 12px',
                                            border: '1px solid #ddd',
                                            borderRadius: '4px',
                                            background: '#fff',
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                            color: '#6c757d'
                                        }}
                                    >
                                        Editar
                                    </button>
                                    <button 
                                        onClick={() => handleDelete(link.id)}
                                        style={{
                                            padding: '6px 12px',
                                            border: '1px solid #dc3545',
                                            borderRadius: '4px',
                                            background: '#fff',
                                            color: '#dc3545',
                                            cursor: 'pointer',
                                            fontSize: '14px'
                                        }}
                                    >
                                        Eliminar
                                    </button>
                                </div>
                            </div>
                            {link.description && (
                                <p style={{
                                    ...styles.linkDescription,
                                    margin: 0,
                                    fontSize: '15px',
                                    lineHeight: '1.5',
                                    color: '#444'
                                }}>
                                    {link.description}
                                </p>
                            )}
                        </div>
                    )
                ))}
            </div>

            <div style={{ paddingBottom: '50px' }} />
        </div>
    );
}

export default EnllaçosApp;
