function DummyButton() {
    return (
        <div className="floating-button hidden">
            <div className="floating-button-icon">
                &#9989;
            </div>
        </div>
    )
}

export default DummyButton;