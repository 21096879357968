import React, { useState, useEffect } from 'react';
import { fetchAPI } from '../../../utils/utils';
import moment from 'moment';
import { applyTimeZone } from './LlistaAssistencies';

function AssistenciesLog({ eventId, castellersInfo, data_inici, data_fi }) {
    const [assistenciesLog, setAssistenciesLog] = useState([]);
    const [visibleLogs, setVisibleLogs] = useState(10);
    const [showFutureLogs, setShowFutureLogs] = useState(false);
    const [filters, setFilters] = useState({
        fitxats: true,
        vinc: true,
        noVinc: true
    });

    useEffect(() => {
        fetchAPI(`/log_assistencies_event/${eventId}`, (response) => {
            setAssistenciesLog(response.data);
        });
    }, [eventId]);

    const formatAssistenciaEmoji = (assistencia) => {
        if (parseInt(assistencia) === 0) return '❌';
        if (parseInt(assistencia) === 1) return '✅';
        if (parseInt(assistencia) === 2) return '📍';
        return '❓';
    }

    const getCastellerName = (id) => {
        const casteller = castellersInfo?.[id];
        if (!casteller) return 'Anònim';
        if ('mote' in casteller && casteller.mote) return casteller.mote;
        return `${casteller.nom} ${casteller.cognom}`;
    }

    const loadMoreLogs = () => {
        setVisibleLogs(prevVisibleLogs => prevVisibleLogs + 10);
    }

    const toggleFilter = (filterName) => {
        setFilters(prev => ({
            ...prev,
            [filterName]: !prev[filterName]
        }));
    }

    const filterLogs = (log) => {
        const assistencia = parseInt(log['assistència']);
        if (assistencia === 2 && !filters.fitxats) return false;
        if (assistencia === 1 && !filters.vinc) return false;
        if (assistencia === 0 && !filters.noVinc) return false;
        if (!showFutureLogs && new Date(log.updatedAt) >= applyTimeZone(data_inici)) return false;
        return true;
    }

    const filterOutDuplicates = (logs) => {
        return logs.filter((log, index) => {
            if (index === 0) return true;
            const prevLog = logs[index - 1];
            return !(
                log['casteller-id'] === prevLog['casteller-id'] && 
                log['assistència'] === prevLog['assistència']
            );
        });
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <h2 style={{ textAlign: 'center', color: '#333' }}>Historial d'assistències</h2>
            
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', marginBottom: '20px' }}>
                <button 
                    onClick={() => toggleFilter('fitxats')}
                    style={{ 
                        padding: '5px 10px',
                        backgroundColor: filters.fitxats ? '#4a90e2' : '#ccc',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    📍 Fitxats
                </button>
                <button 
                    onClick={() => toggleFilter('vinc')}
                    style={{ 
                        padding: '5px 10px',
                        backgroundColor: filters.vinc ? '#5cb85c' : '#ccc',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    ✅ Vinc
                </button>
                <button 
                    onClick={() => toggleFilter('noVinc')}
                    style={{ 
                        padding: '5px 10px',
                        backgroundColor: filters.noVinc ? '#e74c3c' : '#ccc',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    ❌ No vinc
                </button>
                <button
                    onClick={() => setShowFutureLogs(prev => !prev)}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: showFutureLogs ? '#9b59b6' : '#ccc',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Assistències després de l'inici
                </button>
            </div>

            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {filterOutDuplicates(assistenciesLog)
                    .filter(filterLogs)
                    .sort((a, b) => a.id > b.id ? -1 : 1)
                    .slice(0, visibleLogs)
                    .map((log) => (
                        <li key={log.id} style={{ padding: '10px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.5em' }}>{formatAssistenciaEmoji(log['assistència'])}</span>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>{getCastellerName(log['casteller-id'])}</span>
                            <span style={{ color: '#999' }}>{moment(log.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                        </li>
                    ))}
            </ul>
            {visibleLogs < assistenciesLog.filter(filterLogs).length && (
                <button onClick={loadMoreLogs} style={{ display: 'block', margin: '20px auto', padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Carrega'n més
                </button>
            )}
        </div>
    );
}

export default AssistenciesLog;
