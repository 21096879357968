import React, { useState, useEffect } from 'react';
import { fetchAPI } from '../../../utils/utils';
import Pressable from '../../other/Pressable';

function ChatModal({ onClose, selectedEvent, selectedBundle, selectedVersio, castellersInfo, onMessageCountChange, socket, userId, ...props }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const userInfo = userId in castellersInfo ? castellersInfo[userId] : {}

    const displayName = (user) => {
        const castellerInfo = user in castellersInfo ? castellersInfo[user] : {}
        if (castellerInfo?.mote) return castellerInfo.mote
        return castellerInfo?.nom + ' ' + castellerInfo?.cognom?.[0] + '.'
    }

    useEffect(() => {
        const fetchMessages = () => {
            fetchAPI(
                `/missatges/${selectedEvent}/${selectedBundle}/${selectedVersio}`,
                (data) => {
                    handleNewMessages(data);
                    onMessageCountChange(data.length);
                }
            );
        };

        fetchMessages();
    }, [selectedEvent, selectedBundle, selectedVersio]);

    const handleNewMessages = (data) => {
        setMessages(data);
    };

    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            const messageData = {
                user: userId,
                content: newMessage,
                castell: selectedBundle,
                event: selectedEvent,
                versio: selectedVersio,
            };

            socket.emit('.send_messages', [messageData]);

            // Optimistically update local state
            const updatedMessages = [...messages, messageData];
            setMessages(updatedMessages);
            onMessageCountChange(updatedMessages.length);
            setNewMessage('');

            // Fetch updated messages from the server
            fetchAPI(
                `/missatges/${selectedEvent}/${selectedBundle}/${selectedVersio}`,
                handleNewMessages,
                false,
                true
            );
        }
    };

    const confirmDeleteMessage = (message) => {
        if (window.confirm('Estàs segur que vols esborrar aquest missatge?')) {
            socket.emit('.delete_message', message);

            // Optimistically update local state
            const updatedMessages = messages.filter(m => m.id !== message.id);
            setMessages(updatedMessages);
            onMessageCountChange(updatedMessages.length);

            // Fetch updated messages from the server
            fetchAPI(
                `/missatges/${selectedEvent}/${selectedBundle}/${selectedVersio}`,
                handleNewMessages,
                false,
                true
            );
        }
    };

    return (
        <>
            <div className="chat-modal-backdrop" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 99999,
            }} onClick={onClose} />
            <div className="chat-modal" style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                borderRadius: 10,
                padding: 20,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                zIndex: 100000,
                width: '80%',
                maxWidth: 500,
                maxHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div className="chat-header" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 15,
                }}>
                    <h2 style={{ margin: 0 }}>
                        
                    </h2>
                    <Pressable onClick={onClose} style={{
                        backgroundColor: '#eee',
                        padding: 5,
                        borderRadius: 5,
                        fontSize: 18,
                    }}>
                        ✖️
                    </Pressable>
                </div>
                <div className="chat-messages" style={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    marginBottom: 15,
                }}>
                    {messages.length === 0 ? (
                        <div style={{
                            color: '#666',
                            fontStyle: 'italic',
                            fontSize: 14,
                            marginBottom: 10,
                        }}>
                            No hi ha missatges encara.
                        </div>
                    ) : (
                        messages.map((message, index) => (
                            <div key={index} className="message" style={{
                                marginBottom: 10,
                                padding: 10,
                                backgroundColor: '#f0f0f0',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (parseInt(message.user) === parseInt(userInfo.id) || parseInt(userInfo.es_tecnica) >= 2) {
                                    confirmDeleteMessage(message);
                                }
                            }}
                            >
                                <strong>{displayName(message.user)}: </strong>
                                {message.content}
                                <span className="message-date" style={{
                                    fontSize: '0.8em',
                                    color: '#666',
                                    marginLeft: 10,
                                }}>
                                    {new Date(message.data).toLocaleString()}
                                </span>
                            </div>
                        ))
                    )}
                </div>
                <div className="chat-input" style={{
                    display: 'flex',
                }}>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Escriu un missatge..."
                        style={{
                            flexGrow: 1,
                            marginRight: 10,
                            padding: 10,
                            borderRadius: 5,
                            border: '1px solid #ccc',
                        }}
                    />
                    <Pressable onClick={handleSendMessage} style={{
                        backgroundColor: '#eee',
                        padding: 10,
                        borderRadius: 5,
                        fontSize: 18,
                    }}>
                        📤
                    </Pressable>
                </div>
            </div>
        </>
    );
}

export default ChatModal;
