import Pressable from "../../../other/Pressable";

function BotoImportar(props) {
    const { setImporting } = props;

    const importar = () => {
        setImporting(true)
    }

    return (
        <Pressable onClick={importar} id="importar-container" style={{ backgroundColor: 'rgba(0, 0, 255, 0.2)' }} className={`boto boto-save ${props.disabled ? 'disabled' : ''}`}>
            📥
        </Pressable>
    );
}

export default BotoImportar;